<template>
	<v-container>
		<v-row justify="space-around">
			<v-col align="center" cols="12" ms="8" md="8" lg="8" xl="8">
				<v-alert
					align="start"
					border="left"
					type="error"
					dense
					class="text-subtitle-2 ma-3"
					max-width="600px"
				>
					{{ $t(`no_auth.errors.message`, { tenatId: $store.getters.tenantId }) }}
				</v-alert>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'NoAuth'
};
</script>
