<template>
	<div id="navigation">
		<v-app-bar app color="white" dark clipped-left :class="[environment]">
			<v-app-bar-nav-icon
				v-if="isPrivileged"
				light
				@click.stop="drawer = !drawer"
				:disabled="disabledMenu"
			/>
			<router-link class="d-flex align-center" to="/">
				<v-img
					:src="require('@/assets/logo_head.svg')"
					class="my-3"
					contain
					height="40"
					:width="imgWidth"
				/>
			</router-link>
			<v-spacer />
			<v-toolbar-title class="grey--text text--darken-2 d-none d-sm-block pr-12 mr-12">
				{{ tenant.name }}
			</v-toolbar-title>

			<v-spacer />

			<div v-if="!isMobile">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							href="/assets/operation_guid.pdf"
							target="_blank"
							v-bind="attrs"
							v-on="on"
							icon
							:max-width="24"
							class="mx-2"
						>
							<v-icon>mdi-dark mdi-open-in-new</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('menus.HPlink.tooltip') }}</span>
				</v-tooltip>
				<v-menu bottom left transition="slide-y-transition" open-on-hover offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" :max-width="24" class="mx-2">
							<v-icon>mdi-dark mdi-earth</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-subheader>{{ $t('app.language') }}</v-subheader>
						<v-list-item-group>
							<v-list-item v-for="(language, i) in languages" :key="i">
								<v-list-item-content @click="changeLocale(language.locale)">
									<v-list-item-title>{{ language.label }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
			</div>
			<v-menu
				v-if="!disabledReader"
				bottom
				left
				transition="slide-y-transition"
				open-on-hover
				offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" :max-width="24" class="mx-2">
						<v-icon>mdi-dark mdi-dots-vertical</v-icon>
					</v-btn>
				</template>

				<v-list>
					<div v-if="isMobile">
						<v-list-item href="https://www.shiftinc.jp/">
							<v-list-item-icon>
								<v-icon>mdi-dark mdi-open-in-new</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('menus.HPlink.tooltip') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click.stop="selectLanguage = !selectLanguage">
							<v-list-item-icon>
								<v-icon>mdi-dark mdi-earth</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('app.language') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
					<v-list-item @click.stop="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ $t('point_reader.logout.menu') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" color="white" app clipped bottom disable-resize-watcher>
			<v-list dense>
				<v-list-item-group active-class="list-item" v-model="selected" max="1" color="red">
					<v-list-item
						link
						class="list-item"
						v-for="menu in displayMenus"
						:key="menu.route"
						:to="menu.route"
						exact
						@click="drawer = false"
					>
						<v-list-item-action>
							<v-icon>{{ menu.icon }}</v-icon>
						</v-list-item-action>
						<v-tooltip bottom open-delay="1250">
							<template v-slot:activator="{ on, attrs }">
								<v-list-item-content v-bind="attrs" v-on="on">
									<v-list-item-title>{{ $t(menu.title) }}</v-list-item-title>
								</v-list-item-content>
							</template>
							<span>{{ $t(menu.tooltip) }}</span>
						</v-tooltip>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
import axios from 'axios';
import qs from 'qs';

export default {
	props: {
		disabledReader: {
			type: Boolean,
			default: false,
			required: false
		},
		disabledMenu: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data: () => ({
		drawer: false,
		selected: null,
		languages: [
			{
				locale: 'ja',
				label: '日本語'
			},
			{
				locale: 'en',
				label: 'English'
			}
		],
		selectLanguage: false,
		displayMenus: [
			{
				route: '/',
				icon: 'mdi-home',
				title: 'menus.home.title',
				tooltip: 'menus.home.tooltip'
			},
			{
				route: '/admin',
				icon: 'mdi-security',
				title: 'menus.admin.title',
				tooltip: 'menus.admin.tooltip'
			},
			{
				route: '/admin/accounts',
				icon: 'mdi-account-multiple',
				title: 'menus.admin.accounts.title',
				tooltip: 'menus.admin.accounts.tooltip'
			},
			{
				route: '/admin/tenant',
				icon: 'mdi-domain',
				title: 'menus.admin.tenant.title',
				tooltip: 'menus.admin.tenant.tooltip'
			}
		],
		tenant: { name: null }
	}),
	computed: {
		environment() {
			return this.$store.getters.env;
		},
		isMobile() {
			return this.$vuetify.breakpoint.xs;
		},
		imgWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 180;
				default:
					return 220;
			}
		},
		isPrivileged() {
			const { role } = this.$store.getters.user;
			return role === 'privilege' || role === 'admin';
		}
	},
	created() {
		this.tenant.name = this.$store.getters.tenant?.name || '';
		document.title = this.$store.getters.tenant?.title || '';
	},
	methods: {
		changeLocale(locale) {
			this.$i18n.locale = locale;
			this.$vuetify.lang.current = locale;
			switch (locale) {
				case 'en':
					document.getElementsByTagName('html').item(0).lang = 'en';
					break;
				default:
					this.$i18n.locale = 'ja';
					this.$vuetify.lang.current = 'ja';
					document.getElementsByTagName('html').item(0).lang = 'ja';
					break;
			}
			this.selectLanguage = false;
		},
		async logout() {
			const {
				data: { client_uri: clientUri }
			} = await axios.get(`/logout/client/uri`);

			window.open(
				`${clientUri}/logout/?${qs.stringify({
					ui_locales: this.$i18n.locale,
					tenant_id: this.$store.getters.tenantId,
					account_token: this.$store.getters.accountToken
				})}`,
				'_self'
			);
		}
	}
};
</script>
<style lang="sass" scoped>
#navigation::v-deep
	.list-item
		border-left: solid 3px transparent
		&.v-list-item--active
			border-left: solid 3px #D70027
	.red_list .v-list-item-group .v-list-item--active
		background-color: red
		color: white

.env-template
	&::after
		text-align: center
		position: absolute
		border-radius: 0 0 5px 5px
		height: 16px
		left: calc(50% - 50px)
		color: white
		font-size: 6px
		width: 100px

header.env-local
		border-bottom: 3px solid rgba(64, 64, 64, 0.8) !important
		&::after
			@extend .env-template
			content: 'local'
			background-color:  rgba(64, 64, 64, 0.8)
header.env-sandbox
		border-bottom: 3px solid rgba(255, 64, 64, 0.8) !important
		&::after
			@extend .env-template
			content: 'sandbox'
			background-color:  rgba(255, 64, 64, 0.8)
</style>
