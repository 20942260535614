<template>
	<v-container>
		<v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbItems"></v-breadcrumbs>

		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="clearLabel()"
		>
			{{ $t(`errors.${errorLabel}`) }}
		</v-alert>

		<v-row>
			<v-col cols="12" md="4" lg="4" xl="4">
				<v-card>
					<v-form v-model="valid" ref="form">
						<v-card-title>{{ $t('myfeedbacks.edit.card_title') }}</v-card-title>
						<v-card-text>
							<v-text-field
								v-model="feedback.title"
								outlined
								dense
								validate-on-blur
								:rules="titleValidationRules"
								:disabled="disabled"
								counter="1024"
								:label="$t('myfeedbacks.edit.title')"
							/>
							<v-textarea
								v-model="feedback.contents"
								outlined
								dense
								validate-on-blur
								:rules="contentsValidationRules"
								:disabled="disabled"
								counter="65535"
								:label="$t('myfeedbacks.edit.contents')"
							/>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="primary" @click="submit" block :disabled="!valid">
								{{ $t('myfeedbacks.edit.submit') }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-col>

			<v-col cols="12" md="8" lg="8" xl="8">
				<h2 class="mb-2">{{ $t('myfeedbacks.list_items.title') }}</h2>
				<v-list v-if="!isNoData" three-line class="overflow-y-auto" :max-height="maxListHeight">
					<v-list-item-group color="primary" v-model="selected">
						<template v-for="(feedback, index) in displayFeedbacks">
							<v-list-item
								:key="feedback.id"
								:disabled="!enabled(feedback)"
								@click="choiceEditFeedback(feedback)"
							>
								<v-list-item-icon>
									<v-icon v-if="enabled(feedback)"> mdi-pencil </v-icon>
									<v-icon v-else disabled> mdi-pencil-off </v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ feedback.title }}</v-list-item-title>
									<v-list-item-subtitle>{{ feedback.contents }}</v-list-item-subtitle>
									<v-row class="pt-2">
										<v-col cols="1" class="d-flex justify-center px-0">
											<v-icon> mdi-account-circle </v-icon>
										</v-col>
										<v-col cols="6" class="d-flex flex-column px-1">
											<div class="text--secondary text-subtitle-2">
												{{
													$t(`feedbacks.list_items.user`, {
														name: feedback.name
													})
												}}
											</div>
											<div class="text--secondary text-subtitle-2">
												{{
													$t(`feedbacks.list_items.created_at`, {
														date: formatedDate(feedback.createdAt)
													})
												}}
											</div>
											<div class="text-body-2">
												<v-chip
													small
													:color="feedback.category === 'request' ? 'indigo' : 'blue-grey'"
													class="white--text"
													style="cursor: pointer"
												>
													{{ $t(`feedbacks.menu.category.${feedback.category}`) }}
												</v-chip>

												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-icon
															v-if="feedback.role === 'privilege' || feedback.role === 'admin'"
															text-color="white"
															class="pl-1"
															v-bind="attrs"
															v-on="on"
															>mdi-security
														</v-icon>
													</template>
													<span>{{ $t(`feedbacks.list_items.is_privileged`) }}</span>
												</v-tooltip>
											</div>
										</v-col>
										<v-col cols="3" class="d-flex align-center pr-0">
											<v-spacer />
											<v-chip
												small
												label
												:color="statusColor(feedback.status)"
												text-color="white"
												style="cursor: pointer"
											>
												{{ $t(`feedbacks.menu.status.${feedback.status}`) }}
											</v-chip>
										</v-col>
										<v-col cols="2" class="d-flex align-center pl-0">
											<v-spacer />
											<v-btn disabled small text rounded class="ml-1">
												<v-icon left>mdi-thumb-up </v-icon>
												{{ feedback.totalVote }}
											</v-btn>
										</v-col>
									</v-row>
								</v-list-item-content>
								<v-list-item-icon>
									<v-icon v-if="enabled(feedback)" @click.stop="confirmDelete(feedback)">
										mdi-delete
									</v-icon>
									<v-icon v-else disabled> mdi-delete-off </v-icon>
								</v-list-item-icon>
							</v-list-item>
							<v-divider v-if="index < myFeedbacks.length - 1" :key="`${index}-divider`" />
						</template>
					</v-list-item-group>
					<div v-intersect="onIntersect"></div>
				</v-list>
				<v-alert v-if="!loading && isNoData" dense type="info" class="mt-4">
					{{ $t(`myfeedbacks.list_items.no_data`) }}
				</v-alert>
				<v-progress-linear v-show="loading" indeterminate />
			</v-col>
		</v-row>

		<v-dialog v-model="dialog.delete" max-width="600px" persistent>
			<v-card>
				<v-card-title>
					{{ $t(`myfeedbacks.dialog.delete.title`) }}
				</v-card-title>
				<v-card-text>
					{{ $t(`myfeedbacks.dialog.delete.text_confirm`, { title: feedback.title }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn text @click="dialog.delete = false">
						{{ $t(`myfeedbacks.dialog.delete.disagree_label`) }}
					</v-btn>
					<v-btn color="primary" @click="deleteFeedback">
						{{ $t(`myfeedbacks.dialog.delete.agree_label`) }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { DateTime } from 'luxon';
import cloneDeep from 'lodash/cloneDeep';

export default {
	name: 'MyFeedbacksPage',
	data: () => ({
		valid: false,
		maxlen: { title: 1024, contents: 65535 },
		disabled: true,
		selected: null,
		height: 0,
		feedback: {},
		myFeedbacks: [],
		dialog: { delete: false },
		loading: false,
		limit: 10,
		counter: 0,
		errorLabel: null
	}),
	computed: {
		breadcrumbItems() {
			return [
				{
					text: this.$t('myfeedbacks.breadcrumbs.home'),
					disabled: false,
					to: '/'
				},
				{
					text: this.$t('myfeedbacks.breadcrumbs.myfeedbacks'),
					disabled: true
				}
			];
		},
		titleValidationRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('myfeedbacks.edit.title') }),
				(v) =>
					(v && v.length <= this.maxlen.title) ||
					this.$t('errors.maxlen', {
						key: this.$t('myfeedbacks.edit.title'),
						maxlen: this.maxlen.title
					})
			];
		},
		contentsValidationRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('myfeedbacks.edit.contents') }),
				(v) =>
					(v && v.length <= this.maxlen.contents) ||
					this.$t('errors.maxlen', {
						key: this.$t('myfeedbacks.edit.contents'),
						maxlen: this.maxlen.contents
					})
			];
		},
		enabled() {
			return (feedback) => feedback.totalVote === 0 && feedback.status === 'entry';
		},
		displayFeedbacks() {
			return this.myFeedbacks.slice(0, this.limit * this.counter);
		},
		isNoData() {
			return !this.myFeedbacks.length;
		},
		maxListHeight() {
			console.log(this.height);
			return this.height > 1000 ? 800 : this.height - 180;
		},
		formatedDate() {
			return (unixtime) =>
				DateTime.fromSeconds(unixtime).setLocale(this.$i18n.locale).toFormat('yyyy-LL-dd HH:mm');
		},
		statusColor() {
			return (status) => {
				switch (status) {
					case 'entry':
						return 'cyan darken-3';
					case 'inprogress':
						return 'deep-orange darken-3';
					case 'developing':
						return 'green darken-3';
					case 'completed':
						return 'pink darken-3';
					case 'resolved':
						return 'orange darken-3';
					case 'rejected':
						return 'deep-purple darken-3';
					default:
						return 'grey';
				}
			};
		}
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		selected(newValue, oldValue) {
			if (newValue === null || newValue === undefined) this.reset();
			else this.disabled = false;
		}
	},
	async created() {
		await this.init();
		this.height = window.innerHeight;
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		async init() {
			this.loading = true;
			try {
				await this.fetchMyFeedbacks();
				this.randomSetTimeout();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.loading = false;
			}
		},
		async fetchMyFeedbacks() {
			const {
				data: { feedbacks }
			} = await this.$axios.self.get(`/feedbacks`, { params: { isOnlySelf: true } });
			this.myFeedbacks = feedbacks;
		},
		async choiceEditFeedback(feedback) {
			this.feedback = cloneDeep(feedback);

			setTimeout(() => {
				this.$refs.form.validate();
			}, 100);
		},
		async submit() {
			try {
				this.clearLabel();
				await this.$axios.self.patch(`/feedback/${this.feedback.id}`, {
					title: this.feedback.title,
					contents: this.feedback.contents
				});
				await this.init();
				this.reset();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		confirmDelete(feedback) {
			this.dialog.delete = true;
			this.feedback = feedback;
		},
		async deleteFeedback() {
			try {
				this.clearLabel();
				await this.$axios.self.delete(`/feedback/${this.feedback.id}`, {
					data: { feedbackId: this.feedback.id }
				});
				await this.init();

				this.dialog.delete = false;
				this.feedback = {};
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.dialog.delete = false;
			}
		},
		reset() {
			this.disabled = true;
			this.selected = null;
			this.feedback = {};

			setTimeout(() => {
				this.$refs.form.reset();
			}, 100);
		},
		handleResize() {
			this.height = window.innerHeight;
		},
		onIntersect(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.loading = true;
				this.randomSetTimeout();
			}
		},
		randomSetTimeout() {
			const random = Math.floor(Math.random() * 100) + 500;

			setTimeout(() => {
				this.counter += 1;
				this.loading = false;
			}, random);
		},
		clearLabel() {
			this.errorLabel = null;
		}
	}
};
</script>
