<template>
	<v-app>
		<NavigationHeader :disabled-menu="disabledMenu" />
		<v-main class="grey lighten-5">
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader.vue';

export default {
	name: 'HomeView',
	components: {
		NavigationHeader
	},
	computed: {
		locale() {
			return this.$store.getters.user.locale;
		},
		disabledMenu() {
			return this.$route.path === '/notfound' || this.$route.path === '/noauth';
		}
	},
	async created() {
		await this.fetchUser();
		this.setInitLocale(this.locale);
	},
	methods: {
		async fetchUser() {
			try {
				const { data } = await this.$axios.oidc.get(`/api/v1/user`);
				console.log(data);
			} catch (e) {
				console.error(e);
			}
		},
		setInitLocale(locale) {
			this.$i18n.locale = locale;
			this.$vuetify.lang.current = locale;
			switch (locale) {
				case 'en':
					document.getElementsByTagName('html').item(0).lang = 'en';
					break;
				default:
					document.getElementsByTagName('html').item(0).lang = 'ja';
					break;
			}
		}
	}
};
</script>
