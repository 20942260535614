<template>
	<div>
		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="clearLabel()"
		>
			{{ $t(`errors.${errorLabel}`) }}
		</v-alert>

		<v-row>
			<v-col cols="12" md="4" lg="4" xl="4">
				<v-card class="mt-2">
					<v-container>
						<v-row>
							<v-col cols="10" class="pa-0">
								<v-card-title class="d-flex flex-column align-start">
									{{ feedback.title }}

									<v-container>
										<v-row>
											<v-col cols="10" class="pa-0 pr-2">
												<v-chip
													v-if="!editable.feedback"
													small
													label
													:color="statusColor(feedback.status)"
													text-color="white"
												>
													{{ $t(`feedbacks.menu.status.${feedback.status}`) }}
												</v-chip>
												<v-select
													v-else
													:value="feedback.status"
													@input="editStatus"
													:items="feedbackStatus"
													:label="$t(`feedback_comments.feedback_card.status.label`)"
													dense
													single-line
													hide-details
												/>
											</v-col>
											<v-col cols="2" class="pa-0">
												<v-btn disabled small text rounded>
													<v-icon left>mdi-thumb-up </v-icon>
													{{ feedback.totalVote }}
												</v-btn>
											</v-col>
										</v-row>
									</v-container>
								</v-card-title>
							</v-col>
							<v-col cols="2" class="pa-0 d-flex justify-center">
								<v-checkbox
									v-model="editable.feedback"
									off-icon="mdi-lock"
									on-icon="mdi-lock-open-variant"
									:disabled="!!errorLabel"
								/>
							</v-col>
						</v-row>
					</v-container>

					<v-divider></v-divider>
					<v-card-text>
						<div class="mb-4 text-body-2" style="white-space: pre-line">
							{{ feedback.contents }}
						</div>

						<v-row>
							<v-col cols="2" class="d-flex justify-center px-0">
								<v-icon> mdi-account-circle </v-icon>
							</v-col>
							<v-col cols="10" class="d-flex flex-column px-0">
								<div class="text--secondary text-subtitle-2">
									{{
										$t(`feedbacks.list_items.user`, {
											name: feedback.name
										})
									}}
								</div>
								<div class="text--secondary text-subtitle-2">
									{{
										$t(`feedbacks.list_items.created_at`, {
											date: formatedDate(feedback.createdAt)
										})
									}}
								</div>
								<div class="text-body-2">
									<v-chip
										small
										:color="feedback.category === 'request' ? 'indigo' : 'blue-grey'"
										class="white--text"
									>
										{{ $t(`feedbacks.menu.category.${feedback.category}`) }}
									</v-chip>

									<v-tooltip
										bottom
										v-if="feedback.role === 'privilege' || feedback.role === 'admin'"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-icon text-color="white" class="pl-1" v-bind="attrs" v-on="on">
												mdi-security
											</v-icon>
										</template>
										<span>{{ $t(`feedbacks.list_items.is_privileged`) }}</span>
									</v-tooltip>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>

				<v-card class="mt-2">
					<v-card-text>
						<v-form v-model="valid" ref="form">
							<v-textarea
								v-model="comment.contents"
								solo
								:rules="rules"
								validate-on-blur
								:counter="maxlen"
								rows="3"
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn
							@click="sendComment"
							color="primary"
							class="white--text"
							block
							:disabled="!valid"
						>
							{{ $t(`feedback_comments.post_comment.submit`) }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col cols="12" md="8" lg="8" xl="8">
				<h2 class="mb-2">
					{{
						$t(`feedback_comments.list_items.title`, {
							count: comments.length
						})
					}}
				</h2>
				<v-list v-if="!isNoData" class="overflow-y-auto" :max-height="maxListHeight">
					<template v-for="(comment, index) in displayComments">
						<v-list-item :key="comment.id">
							<v-list-item-action>
								{{ index + 1 }}
							</v-list-item-action>
							<v-list-item-content>
								<v-container class="pa-0">
									<v-row>
										<v-col cols="12" class="pb-0">
											<div
												class="text-body-2"
												style="white-space: pre-line; overflow-wrap: break-word"
											>
												<span v-if="!comment.deletedAt">{{ comment.comments }}</span>
												<span v-else>
													{{ $t(`feedback_comments.list_items.delete_by_privilege`) }}
												</span>
											</div>
										</v-col>
										<v-col cols="1" class="d-flex justify-center px-0 pt-0">
											<v-icon> mdi-account-circle </v-icon>
										</v-col>
										<v-col cols="11" class="d-flex align-center px-0 pt-0">
											<div class="secondary--text text--lighten-1 text-subtitle-2">
												{{
													$t(`feedbacks.list_items.user`, {
														name: comment.name
													})
												}}
											</div>
											<div class="secondary--text text--lighten-1 text-subtitle-2 ml-2">
												{{
													$t(`feedbacks.list_items.created_at`, {
														date: formatedDate(comment.createdAt)
													})
												}}
											</div>

											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-if="comment.role === 'privilege' || 'admin'"
														text-color="white"
														class="pl-1"
														v-bind="attrs"
														v-on="on"
													>
														mdi-security
													</v-icon>
												</template>
												<span>{{ $t(`feedback_comments.list_items.is_privileged`) }}</span>
											</v-tooltip>
										</v-col>
									</v-row>
								</v-container>
							</v-list-item-content>
							<v-list-item-action>
								<v-icon v-if="!comment.deletedAt" @click="confirmDeleteComment(comment, index + 1)">
									mdi-delete-outline
								</v-icon>
							</v-list-item-action>
						</v-list-item>
						<v-divider v-if="index < displayComments.length - 1" :key="`${index}-divider`" />
					</template>
					<div v-intersect="onIntersect"></div>
				</v-list>
				<v-alert v-if="!loading && isNoData" dense type="info" class="mt-4">
					{{ $t(`feedback_comments.list_items.no_data`) }}
				</v-alert>
				<v-progress-linear v-show="loading" indeterminate />
			</v-col>
		</v-row>

		<v-dialog v-model="dialog.delete" max-width="600px" persistent>
			<v-card>
				<v-card-title>
					{{ $t(`feedback_comments.dialog.delete.title`) }}
				</v-card-title>
				<v-card-text>
					{{ $t(`feedback_comments.dialog.delete.text_confirm`, { number: comment.number }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn text @click="dialog.delete = false">
						{{ $t(`feedback_comments.dialog.delete.disagree_label`) }}
					</v-btn>
					<v-btn color="primary" @click="deleteComment">
						{{ $t(`feedback_comments.dialog.delete.agree_label`) }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.editStatusToReject" max-width="600px">
			<v-card>
				<v-card-title>
					{{ $t(`admin_comments.dialog.feedback.message.confirmeditStatusToReject`) }}
				</v-card-title>
				<v-card-subtitle>
					{{ $t(`admin_comments.dialog.feedback.message.description`) }}
				</v-card-subtitle>
				<v-card-text>
					{{ feedback.title }}
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn min-width="100px" @click="cancelEditStatusToReject">
						{{ $t(`admin_comments.button.cancel`) }}
					</v-btn>
					<v-btn color="error" min-width="100px" @click="editStatusToReject">
						{{ $t(`admin_comments.button.change`) }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
	name: 'AdminFeedbackComments',
	data: () => ({
		valid: false,
		maxlen: 65535,
		height: 0,
		editable: { feedback: false },
		feedback: { id: null, status: null },
		comments: [],
		comment: { number: null, id: '', contents: null },
		dialog: { delete: false, editStatusToReject: false },
		loading: false,
		limit: 10,
		counter: 0,
		errorLabel: null
	}),
	computed: {
		displayComments() {
			return this.comments.slice(0, this.limit * this.counter);
		},
		isNoData() {
			return !this.comments.length;
		},
		feedbackStatus() {
			return [
				{ text: this.$t(`feedback_comments.feedback_card.status.entry`), value: 'entry' },
				{ text: this.$t(`feedback_comments.feedback_card.status.inprogress`), value: 'inprogress' },
				{ text: this.$t(`feedback_comments.feedback_card.status.developing`), value: 'developing' },
				{ text: this.$t(`feedback_comments.feedback_card.status.completed`), value: 'completed' },
				{ text: this.$t(`feedback_comments.feedback_card.status.resolved`), value: 'resolved' },
				{ text: this.$t(`feedback_comments.feedback_card.status.rejected`), value: 'rejected' }
			];
		},
		maxListHeight() {
			return this.height > 1000 ? 800 : this.height - 180;
		},
		formatedDate() {
			return (unixtime) =>
				DateTime.fromSeconds(Number(unixtime))
					.setLocale(this.$i18n.locale)
					.toFormat('yyyy-LL-dd HH:mm');
		},
		statusColor() {
			return (status) => {
				switch (status) {
					case 'entry':
						return 'cyan darken-3';
					case 'inprogress':
						return 'deep-orange darken-3';
					case 'developing':
						return 'green darken-3';
					case 'completed':
						return 'pink darken-3';
					case 'resolved':
						return 'orange darken-3';
					case 'rejected':
						return 'deep-purple darken-3';
					default:
						return 'grey';
				}
			};
		},
		rules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: 'コメント' }),
				(v) =>
					(v && v.length <= this.maxlen) ||
					this.$t('errors.maxlen', { key: 'コメント', maxlen: this.maxlen })
			];
		}
	},
	async created() {
		await this.init();
		this.height = window.innerHeight;
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		async init() {
			this.loading = true;

			if (!this.$route.params.id) {
				this.errorLabel = 'error_unknown';
				this.loading = false;
				return;
			}
			this.feedback.id = this.$route.params.id;

			try {
				await this.fetchFeedback();
				await this.fetchComments();

				this.randomSetTimeout();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.loading = false;
			}
		},
		async fetchFeedback() {
			const { data } = await this.$axios.internalSelf.get(`/feedback/${this.feedback.id}`);
			this.feedback = data;
		},
		async fetchComments() {
			const {
				data: { feedbackComments }
			} = await this.$axios.internalSelf.get(`/feedback/${this.feedback.id}/comments`, {
				params: { isAsc: true }
			});
			this.comments = feedbackComments;
		},
		confirmDeleteComment(comment, number) {
			this.dialog.delete = true;
			this.comment.id = comment.id;
			this.comment.number = number;
		},
		async sendComment() {
			try {
				this.clearLabel();
				await this.$axios.internalSelf.post(`/feedback/${this.feedback.id}/comment`, {
					comments: this.comment.contents
				});
				this.comment.contents = null;
				this.$refs.form.reset();

				await this.fetchComments();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		async deleteComment() {
			try {
				this.clearLabel();
				await this.$axios.internalSelf.delete(
					`/feedback/${this.feedback.id}/comment/${this.comment.id}`,
					{ data: { commentId: this.comment.id } }
				);
				await this.fetchComments();

				this.comment.id = null;
				this.comment.number = null;
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.dialog.delete = false;
			}
		},
		async editStatus(status) {
			if (status === 'rejected') {
				this.dialog.editStatusToReject = true;
				return;
			}
			await this.editFeedback(status);
		},
		cancelEditStatusToReject() {
			this.dialog.editStatusToReject = false;
			this.editable.feedback = false;
		},
		async editStatusToReject() {
			this.dialog.editStatusToReject = false;
			await this.editFeedback('rejected');
		},
		async editFeedback(status) {
			try {
				await this.$axios.internalSelf.patch(`/feedback/${this.feedback.id}`, {
					status
				});
				await this.fetchFeedback();
				await this.fetchComments();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.editable.feedback = false;
			}
		},
		handleResize() {
			this.height = window.innerHeight;
		},
		onIntersect(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.loading = true;
				this.randomSetTimeout();
			}
		},
		randomSetTimeout() {
			const random = Math.floor(Math.random() * 100) + 500;

			setTimeout(() => {
				this.counter += 1;
				this.loading = false;
			}, random);
		},
		clearLabel() {
			this.errorLabel = null;
		}
	}
};
</script>
