import axios from 'axios';
import { strict as assert } from 'assert';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import axiosCaseConverter from 'simple-axios-case-converter';

export default (token, tenantId) => {
	assert.ok(token);
	assert.ok(tenantId);

	const proxyGroups = [`self`, `internalSelf`, `internal`, `oidc`];
	const schemas = {};
	const getBaseUrl = (proxyGroup) => {
		if (proxyGroup === `self`) return `/api/v1/tenant/${tenantId}`;
		if (proxyGroup === `internalSelf`) return `/api/v1/internal/tenant/${tenantId}`;
		if (proxyGroup === `internal`) return `/api/v1/internal`;
		return `/proxy/${proxyGroup}`;
	};

	proxyGroups.forEach((proxyGroup) => {
		schemas[proxyGroup] = axios.create({
			baseURL: getBaseUrl(proxyGroup),
			headers: {
				common: {
					Authorization: `Bearer ${token}`
				}
			}
		});
		axiosCaseConverter(schemas[proxyGroup]);
		// 今後4XX 系も追加する場合、サーバーへの負荷関連429を除く
		axiosRetry(schemas[proxyGroup], {
			retryCondition: (error) =>
				error.code !== 'ECONNABORTED' &&
				(!error.response || (error.response.status >= 500 && error.response.status !== 503)),
			retryDelay: exponentialDelay
		});
	});

	return schemas;
};
