<template>
	<v-container>
		<v-breadcrumbs class="pl-0 pt-0 pb-2" :items="breadcrumbItems" />

		<v-alert dense type="warning" class="body-2"> {{ $t('admin_home.alert') }} </v-alert>

		<router-view />
	</v-container>
</template>

<script>
export default {
	name: 'AdminHome',
	data: () => ({
		baseBreadcrumbItems: [
			{
				textId: 'admin_home.breadcrumbs.admin_top',
				disabled: false,
				exact: true,
				to: '/admin',
				name: 'admin'
			},
			{
				textId: 'admin_home.breadcrumbs.comments',
				disabled: false,
				exact: true,
				to: '/admin/feedback/:id/comments',
				name: 'adminFeedbackComments'
			},
			{
				textId: 'admin_home.breadcrumbs.accounts',
				disabled: false,
				exact: true,
				to: '/admin/accounts',
				name: 'adminAccounts'
			},
			{
				textId: 'admin_home.breadcrumbs.tenant.settings',
				disabled: false,
				exact: true,
				to: '/admin/tenant',
				name: 'adminTenant'
			}
		]
	}),
	computed: {
		breadcrumbItems() {
			const lastRouteName = this.$route.matched[this.$route.matched.length - 1].name;
			return this.baseBreadcrumbItems
				.filter(
					(item) =>
						this.$route.matched.some((matchedRoute) => matchedRoute.path === item.to) ||
						item.to === '/'
				)
				.map((item) => ({
					...item,
					text: this.$t(item.textId),
					disabled: lastRouteName === item.name
				}));
		}
	}
};
</script>
