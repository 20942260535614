<template>
	<v-container>
		<v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbItems" />

		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="this.errorLabel = null"
		>
			{{ $t(`errors.${errorLabel}`) }}
		</v-alert>

		<v-row class="justify-center">
			<v-col cols="4">
				<v-card>
					<v-form v-model="valid" ref="form">
						<v-card-title>{{ $t('new_feedback.title') }}</v-card-title>
						<v-card-text>
							<v-alert v-if="!isRequestable" type="info" outlined>
								{{ $t('new_feedback.outside_request_period') }}
							</v-alert>
							<v-radio-group v-model="feedback.category" row validate-on-blur>
								<template v-for="radio of radios">
									<v-radio
										:value="radio.value"
										:label="radio.label"
										:key="radio.value"
										:disabled="radio.value === 'request' && !isRequestable"
									/>
								</template>
							</v-radio-group>
							<v-text-field
								v-model="feedback.title"
								outlined
								dense
								validate-on-blur
								:rules="titleValidationRules"
								counter="1024"
								:label="$t('myfeedbacks.edit.title')"
							/>
							<v-textarea
								v-model="feedback.contents"
								outlined
								dense
								counter="65535"
								validate-on-blur
								:rules="contentsValidationRules"
								:label="$t('myfeedbacks.edit.contents')"
							/>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="primary" @click="submit" block :disabled="!valid">
								{{ $t('new_feedback.submit') }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'NewFeedback',
	data: () => ({
		valid: false,
		maxlen: { title: 1024, contents: 65535 },
		feedback: {
			category: 'request',
			title: null,
			contents: null
		},
		isRequestable: null,
		errorLabel: null
	}),
	computed: {
		breadcrumbItems() {
			return [
				{
					text: this.$t('new_feedback.breadcrumbs.home'),
					disabled: false,
					to: '/'
				},
				{
					text: this.$t('new_feedback.breadcrumbs.new'),
					disabled: true
				}
			];
		},
		titleValidationRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('myfeedbacks.edit.title') }),
				(v) =>
					(v && v.length <= this.maxlen.title) ||
					this.$t('errors.maxlen', {
						key: this.$t('myfeedbacks.edit.title'),
						maxlen: this.maxlen.title
					})
			];
		},
		contentsValidationRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('myfeedbacks.edit.contents') }),
				(v) =>
					(v && v.length <= this.maxlen.contents) ||
					this.$t('errors.maxlen', {
						key: this.$t('myfeedbacks.edit.contents'),
						maxlen: this.maxlen.contents
					})
			];
		},
		radios() {
			return [
				{ label: this.$t('new_feedback.radio.request'), value: 'request' },
				{ label: this.$t('new_feedback.radio.question'), value: 'question' }
			];
		}
	},
	created() {
		this.fetchIsRequestable();
	},
	methods: {
		async submit() {
			try {
				await this.$axios.self.post(`/feedback`, this.feedback);
				this.$router.push('/');
			} catch (e) {
				this.errorLabel = 'unknown';
			}
		},
		async fetchIsRequestable() {
			const {
				data: { isRequestable }
			} = await this.$axios.self.get('/');
			this.isRequestable = isRequestable;
			if (!isRequestable) {
				this.feedback.category = 'question';
			}
		}
	}
};
</script>
