<template>
	<div>
		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="clearLabel()"
		>
			<span v-if="!!errorLabel">{{ $t(`errors.${errorLabel}`) }}</span>
		</v-alert>

		<v-data-table
			:headers="headers"
			:items="displayAccounts"
			class="elevation-1"
			:search="search"
			:loading="loading"
			dense
		>
			<template #top>
				<v-text-field
					v-model="search"
					prepend-inner-icon="mdi-magnify"
					:label="$t(`accounts.table.search`)"
					single-line
					class="mx-4"
				/>
			</template>
			<template #[`item.role`]="{ item }">
				<v-switch
					v-model="item.role"
					dense
					hide-details
					class="my-2 pa-0"
					:label="$t(`accounts.table.role.${item.role}`)"
					:value="item.role"
					true-value="privilege"
					false-value="member"
					@change="updateRole(item)"
				/>
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	name: 'Accounts',
	data: () => ({
		errorLabel: null,
		search: '',
		accounts: [],
		groupUsers: [],
		loading: false
	}),
	computed: {
		headers() {
			return [
				{
					text: this.$t('accounts.email'),
					sortable: false,
					value: 'email'
				},
				{
					text: this.$t('accounts.name'),
					sortable: false,
					value: 'name'
				},
				{
					text: this.$t('accounts.isPrivileged'),
					sortable: false,
					value: 'role'
				}
			];
		},
		groupId() {
			return this.$store.getters.tenant.groupId;
		},
		displayAccounts() {
			return (
				this.accounts
					.filter((account) => account.role === 'member' || account.role === 'privilege')
					// グループ紐づきある場合、グループに紐づくユーザーのみ表示
					.filter((account) => {
						if (!this.groupId) return true;
						return this.groupUsers.some(
							(groupUser) => groupUser.accountId === account.shiftAccountId
						);
					})
			);
		}
	},
	async created() {
		await this.init();
	},
	methods: {
		async init() {
			this.loading = true;
			try {
				await this.fetchAccounts();
				if (this.groupId) await this.fetchGroupUsers();
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		// TODO 1001件目以降のアカウントを取得する
		async fetchAccounts() {
			const {
				data: { accounts }
			} = await this.$axios.internal.get(`/tenant/accounts`, {
				params: { limit: 1000 }
			});
			this.accounts = accounts;
		},
		async fetchGroupUsers() {
			const {
				data: { groupUsers }
			} = await this.$axios.oidc.get(`/api/v1/group/${this.groupId}/users`);
			this.groupUsers = groupUsers;
		},
		async updateRole(item) {
			this.clearLabel();
			this.loading = true;
			try {
				await this.$axios.internal.patch(`/tenant/account/${item.id}`, { role: item.role });
				await this.fetchAccounts();
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		clearLabel() {
			this.errorLabel = null;
		}
	}
};
</script>
