<template>
	<div>
		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="clearLabel()"
		>
			{{ $t(`errors.${errorLabel}`) }}
		</v-alert>

		<v-row>
			<v-col cols="12" md="4" lg="4" xl="4">
				<v-sheet elevation="1">
					<v-list subheader dense>
						<v-subheader>{{ $t(`feedbacks.menu.subheader.status`) }}</v-subheader>
						<v-list-item-group active-class="primary--text" v-model="selected.filter">
							<template v-for="filter of filters">
								<v-list-item v-if="filter.type === 'status'" :key="filter.id">
									<v-list-item-content>
										<v-list-item-title>
											{{ $t(`feedbacks.menu.status.${filter.id}`) }}
										</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text>{{ filter.total }}</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</template>

							<v-divider />

							<v-subheader>{{ $t(`feedbacks.menu.subheader.category`) }}</v-subheader>
							<template v-for="filter of filters">
								<v-list-item v-if="filter.type === 'category'" :key="filter.id">
									<v-list-item-content>
										<v-list-item-title>
											{{ $t(`feedbacks.menu.category.${filter.id}`) }}
										</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text>{{ filter.total }}</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</template>
						</v-list-item-group>
					</v-list>
				</v-sheet>
			</v-col>

			<v-col cols="12" md="8" lg="8" xl="8">
				<v-tabs v-model="selected.tab" align-with-title elevation="1">
					<v-tab class="ml-0" v-for="tab of tabs" :key="tab">
						{{ $t(`feedbacks.tabs.${tab}`) }}
					</v-tab>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search.text"
						@click:clear="clearSearchText"
						@keyup.enter="clickSearch"
						class="mt-1"
						dense
						hide-details
						flat
						solo
						clearable
						outlined
					>
						<template v-slot:append>
							<v-btn @click="clickSearch" icon><v-icon> mdi-magnify </v-icon></v-btn>
						</template>
					</v-text-field>
				</v-tabs>

				<v-tabs-items v-model="selected.tab">
					<v-tab-item v-for="tab of tabs" :key="tab">
						<v-list-item-group color="primary">
							<v-list three-line class="overflow-y-auto" :max-height="maxListHeight">
								<template v-for="(feedback, index) in displayFeedbacks">
									<v-list-item
										@click="$router.push(`/admin/feedback/${feedback.id}/comments`)"
										:key="feedback.id"
									>
										<v-list-item-action>
											<v-card>
												<v-card-title class="justify-center px-5">
													{{ feedback.totalVote }}
												</v-card-title>
												<v-divider />
												<v-card-text class="py-2 px-3">
													{{ $t(`feedbacks.list_items.vote_count`) }}
												</v-card-text>
											</v-card>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>{{ feedback.title }}</v-list-item-title>
											<v-list-item-subtitle>{{ feedback.contents }}</v-list-item-subtitle>
											<v-row class="pt-2">
												<v-col cols="1" class="d-flex justify-center px-0">
													<v-icon> mdi-account-circle </v-icon>
												</v-col>
												<v-col cols="6" class="d-flex flex-column px-1">
													<div class="text--secondary text-subtitle-2">
														{{
															$t(`feedbacks.list_items.user`, {
																name: feedback.name
															})
														}}
													</div>
													<div class="text--secondary text-subtitle-2">
														{{
															$t(`feedbacks.list_items.created_at`, {
																date: formattedDate(feedback.createdAt)
															})
														}}
													</div>
													<div class="text-body-2">
														<v-chip
															small
															:color="feedback.category === 'request' ? 'indigo' : 'blue-grey'"
															class="white--text"
															style="cursor: pointer"
														>
															{{ $t(`feedbacks.menu.category.${feedback.category}`) }}
														</v-chip>

														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-icon
																	v-if="feedback.role === 'privilege' || feedback.role === 'admin'"
																	text-color="white"
																	class="pl-1"
																	v-bind="attrs"
																	v-on="on"
																	>mdi-security
																</v-icon>
															</template>
															<span>{{ $t(`feedbacks.list_items.is_privileged`) }}</span>
														</v-tooltip>
													</div>
												</v-col>
												<v-col cols="3" class="d-flex align-center pr-0">
													<v-spacer />
													<v-chip
														small
														label
														:color="statusColor(feedback.status)"
														text-color="white"
														style="cursor: pointer"
													>
														{{ $t(`feedbacks.menu.status.${feedback.status}`) }}
													</v-chip>
												</v-col>
												<v-col cols="2" class="d-flex align-center pl-0">
													<v-spacer />
													<v-icon left small> mdi-comment-multiple-outline </v-icon>
													<span class="body-2">{{ feedback.totalComment }}</span>
												</v-col>
											</v-row>
										</v-list-item-content>
									</v-list-item>
									<v-divider v-if="index < displayFeedbacks.length - 1" :key="`${index}-divider`" />
								</template>
								<div v-intersect="onIntersect"></div>
							</v-list>
						</v-list-item-group>
						<v-progress-linear v-show="loading" indeterminate />
					</v-tab-item>
				</v-tabs-items>

				<v-alert v-if="!loading && isNoData" dense type="info" class="mt-4">
					{{ $t(`feedbacks.list_items.no_data`) }}
				</v-alert>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
	name: 'AdminFeedbacks',
	data: () => ({
		tab: null,
		height: 0,
		search: { text: '', filterText: '' },
		allFeedbacks: [],
		tabs: ['recent', 'popular', 'hot'],
		filters: [
			{ id: 'entry', type: 'status', total: 0 },
			{ id: 'inprogress', type: 'status', total: 0 },
			{ id: 'developing', type: 'status', total: 0 },
			{ id: 'completed', type: 'status', total: 0 },
			{ id: 'resolved', type: 'status', total: 0 },
			{ id: 'rejected', type: 'status', total: 0 },
			{ id: 'request', type: 'category', total: 0 },
			{ id: 'question', type: 'category', total: 0 }
		],
		selected: { tab: 0, filter: 0 },
		loading: false,
		limit: 10,
		counter: 0,
		errorLabel: null
	}),
	computed: {
		displayFeedbacks() {
			const feedbacks = [];

			this.allFeedbacks
				.filter(
					(feedback) =>
						feedback.title.includes(this.search.filterText) ||
						feedback.contents.includes(this.search.filterText)
				)
				.filter((feedback) => {
					if (Number.isInteger(this.selected.filter)) {
						const index = this.selected.filter;
						return feedback[this.filters[index].type] === this.filters[index].id;
					}
					return true;
				})
				.forEach((feedback) => {
					feedbacks.push(feedback);
				});

			// tabの切り替え
			if (this.tabs[`${this.selected.tab}`] === 'popular')
				feedbacks.sort((a, b) => (a.totalVote < b.totalVote ? 1 : -1));
			if (this.tabs[`${this.selected.tab}`] === 'hot')
				feedbacks.sort((a, b) => (a.totalComment < b.totalComment ? 1 : -1));

			return feedbacks.slice(0, this.limit * this.counter);
		},
		maxListHeight() {
			return this.height - 180;
		},
		formattedDate() {
			return (unixtime) =>
				DateTime.fromSeconds(unixtime).setLocale(this.$i18n.locale).toFormat('yyyy-LL-dd HH:mm');
		},
		selectedTabForAdmin() {
			return this.$store.getters.selectedTabForAdmin;
		},
		isNoData() {
			return !this.displayFeedbacks.length;
		},
		statusColor() {
			return (status) => {
				switch (status) {
					case 'entry':
						return 'cyan darken-3';
					case 'inprogress':
						return 'deep-orange darken-3';
					case 'developing':
						return 'green darken-3';
					case 'completed':
						return 'pink darken-3';
					case 'resolved':
						return 'orange darken-3';
					case 'rejected':
						return 'deep-purple darken-3';
					default:
						return 'grey';
				}
			};
		}
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		filteredFeedbacks(newValue, oldValue) {
			const maxPageCount = Math.ceil(newValue.length / this.pagination.size);
			if (maxPageCount < this.pagination.current) this.pagination.current = 1;
		}
	},
	async created() {
		this.selected.tab = this.selectedTabForAdmin;
		await this.init();
		this.height = window.innerHeight - 56;
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		this.$store.commit('setSelectedTabForAdmin', this.selected.tab);
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		async init() {
			try {
				this.clearTotalCount();

				await this.fetchAllFeedbacks();

				this.allFeedbacks.forEach((feedback) => {
					this.filters.forEach((origin) => {
						const filter = origin;
						if (filter.id === feedback[filter.type]) filter.total += 1;
					});
				});
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		clearTotalCount() {
			this.filters.forEach((v) => {
				const filter = v;
				filter.total = 0;
			});
		},
		async fetchAllFeedbacks() {
			const {
				data: { feedbacks }
			} = await this.$axios.internalSelf.get(`/feedbacks`);
			this.allFeedbacks = feedbacks;
		},
		clickSearch() {
			this.search.filterText = this.search.text;
		},
		clearSearchText() {
			this.search.filterText = '';
		},
		onIntersect(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.loading = true;

				const random = Math.floor(Math.random() * 100) + 500;

				setTimeout(() => {
					this.counter += 1;
					this.loading = false;
				}, random);
			}
		},
		handleResize() {
			this.height = window.innerHeight;
		},
		clearLabel() {
			this.errorLabel = null;
		}
	}
};
</script>
