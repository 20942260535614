<template>
	<v-container>
		<v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbItems"></v-breadcrumbs>

		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="clearLabel()"
		>
			{{ $t(`errors.${errorLabel}`) }}
		</v-alert>

		<v-row>
			<v-col v-if="showCol4" cols="4"> </v-col>
			<v-col cols="12" md="8" lg="8" xl="8">
				<h2 class="mb-2">{{ $t(`mycomment_feedbacks.list_items.title`) }}</h2>
				<v-list v-if="!isNoData" three-line class="overflow-y-auto" :max-height="maxListHeight">
					<v-list-item-group color="indigo">
						<template v-for="(feedback, index) in displayFeedbacks">
							<v-list-item
								@click="$router.push(`/feedback/${feedback.id}/comments`)"
								:key="feedback.id"
							>
								<v-list-item-action>
									<v-card>
										<v-card-title class="justify-center">{{ feedback.totalVote }}</v-card-title>
										<v-divider />
										<v-btn
											@click.stop="vote(feedback.id)"
											color="primary"
											tile
											:disabled="disabled(avaiableVoteCount, feedback)"
										>
											{{ $t(`feedbacks.list_items.vote_btn`) }}
										</v-btn>
									</v-card>
								</v-list-item-action>

								<v-list-item-content>
									<v-list-item-title>{{ feedback.title }}</v-list-item-title>
									<v-list-item-subtitle>{{ feedback.contents }}</v-list-item-subtitle>
									<v-row class="pt-2">
										<v-col cols="1" class="d-flex justify-center px-0">
											<v-icon> mdi-account-circle </v-icon>
										</v-col>
										<v-col cols="6" class="d-flex flex-column px-1">
											<div class="text--secondary text-subtitle-2">
												{{
													$t(`feedbacks.list_items.user`, {
														name: feedback.userName
													})
												}}
											</div>
											<div class="text--secondary text-subtitle-2">
												{{
													$t(`feedbacks.list_items.created_at`, {
														date: formatedDate(feedback.createdAt)
													})
												}}
											</div>
											<div class="text-body-2">
												<v-chip
													small
													:color="feedback.category === 'request' ? 'indigo' : 'blue-grey'"
													class="white--text"
													style="cursor: pointer"
												>
													{{ $t(`feedbacks.menu.category.${feedback.category}`) }}
												</v-chip>

												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-icon
															v-if="feedback.role === 'privilege' || feedback.role === 'admin'"
															text-color="white"
															class="pl-1"
															v-bind="attrs"
															v-on="on"
															>mdi-security
														</v-icon>
													</template>
													<span>{{ $t(`feedbacks.list_items.is_privileged`) }}</span>
												</v-tooltip>
											</div>
										</v-col>
										<v-col cols="3" class="d-flex align-center pr-0">
											<v-spacer />
											<v-chip
												small
												label
												:color="statusColor(feedback.status)"
												text-color="white"
												style="cursor: pointer"
											>
												{{ $t(`feedbacks.menu.status.${feedback.status}`) }}
											</v-chip>
										</v-col>
										<v-col cols="2" class="d-flex align-center pl-0">
											<v-spacer />
											<v-btn
												@click.stop="navigateToFeedbackCommentsPage(feedback.id)"
												small
												text
												rounded
												class="ml-1"
											>
												<v-icon left> mdi-comment-multiple-outline </v-icon>
												{{ feedback.totalComment }}
											</v-btn>
										</v-col>
									</v-row>
								</v-list-item-content>
							</v-list-item>
							<v-divider v-if="index < displayFeedbacks.length - 1" :key="index" />
						</template>
					</v-list-item-group>
					<div v-intersect="onIntersect"></div>
				</v-list>
				<v-alert v-if="!loading && isNoData" dense type="info" class="mt-4">
					{{ $t(`mycomment_feedbacks.list_items.no_data`) }}
				</v-alert>
				<v-progress-linear v-show="loading" indeterminate />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { DateTime } from 'luxon';

export default {
	name: 'MyCommentFeedbacksPage',
	data: () => ({
		height: 0,
		voteFeedbacks: [],
		commentFeedbacks: [],
		loading: false,
		limit: 10,
		counter: 0,
		errorLabel: null
	}),
	computed: {
		breadcrumbItems() {
			return [
				{
					text: this.$t('mycomment_feedbacks.breadcrumbs.home'),
					disabled: false,
					to: '/'
				},
				{
					text: this.$t('mycomment_feedbacks.breadcrumbs.feedback'),
					disabled: true
				}
			];
		},
		avaiableVoteCount() {
			let totalVoteCast = 0;
			this.voteFeedbacks.forEach((voteFeedback) => {
				totalVoteCast += voteFeedback.votesCast;
			});
			return 5 - totalVoteCast;
		},
		displayFeedbacks() {
			return this.commentFeedbacks.slice(0, this.limit * this.counter);
		},
		showCol4() {
			if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm')
				return false;
			return true;
		},
		isNoData() {
			return !this.commentFeedbacks.length;
		},
		maxListHeight() {
			return this.height > 1000 ? 800 : this.height - 180;
		},
		formatedDate() {
			return (unixtime) =>
				DateTime.fromSeconds(unixtime).setLocale(this.$i18n.locale).toFormat('yyyy-LL-dd HH:mm');
		},
		disabled() {
			return (avaiableVoteCount, feedback) =>
				avaiableVoteCount === 0 ||
				feedback.status === 'developing' ||
				feedback.status === 'completed' ||
				feedback.status === 'resolved' ||
				feedback.status === 'rejected';
		},
		statusColor() {
			return (status) => {
				switch (status) {
					case 'entry':
						return 'cyan darken-3';
					case 'inprogress':
						return 'deep-orange darken-3';
					case 'developing':
						return 'green darken-3';
					case 'completed':
						return 'pink darken-3';
					case 'resolved':
						return 'orange darken-3';
					case 'rejected':
						return 'deep-purple darken-3';
					default:
						return 'grey';
				}
			};
		}
	},
	async created() {
		await this.init();
		this.height = window.innerHeight;
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		async init() {
			try {
				this.loading = true;
				await this.fetchFeedbackComments();
				await this.fetchVoteFeedbacks();

				this.randomSetTimeout();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.loading = false;
			}
		},
		async fetchFeedbackComments() {
			const {
				data: { feedbacks }
			} = await this.$axios.self.get(`/comment/feedbacks`);
			this.commentFeedbacks = feedbacks;
		},
		async fetchVoteFeedbacks() {
			const {
				data: { voteFeedbacks }
			} = await this.$axios.self.get(`/vote/feedbacks`);
			this.voteFeedbacks = voteFeedbacks;
		},
		async vote(feedbackId) {
			let existVote = null;
			try {
				const { data } = await this.$axios.self.get(`/feedback/${feedbackId}/vote`);
				existVote = data;
			} catch (e) {
				// 何もしない
			}

			try {
				this.clearLabel();
				if (existVote) {
					await this.$axios.self.patch(`/feedback/${feedbackId}/vote`, {
						votesCast: existVote.votesCast + 1
					});
					await this.init();
					return;
				}

				await this.$axios.self.post(`/feedback/${feedbackId}/vote`, {
					votesCast: 1
				});
				await this.init();
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 409:
						this.errorLabel = 'reach_max_vote_cast';
						break;
					default:
						break;
				}
			}
		},
		onIntersect(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.loading = true;
				this.randomSetTimeout();
			}
		},
		randomSetTimeout() {
			const random = Math.floor(Math.random() * 100) + 500;

			setTimeout(() => {
				this.counter += 1;
				this.loading = false;
			}, random);
		},
		clearLabel() {
			this.errorLabel = null;
		}
	}
};
</script>
