import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		accountToken: null,
		env: 'env-production',
		selectedTabForAdmin: 0,
		selectedTabForUser: 0,
		tenantId: null,
		user: { locale: 'ja', name: '', accountId: null, role: null, shiftAccountId: null },
		tenant: { groupId: null }
	},
	getters: {
		accountToken: (state) => state.accountToken,
		env: (state) => state.env,
		selectedTabForAdmin: (state) => state.selectedTabForAdmin,
		selectedTabForUser: (state) => state.selectedTabForUser,
		tenantId: (state) => state.tenantId,
		user: (state) => state.user,
		tenant: (state) => state.tenant
	},
	mutations: {
		setAccountToken(state, accountToken) {
			state.accountToken = accountToken;
		},
		setEnv(state, env) {
			state.env = env;
		},
		setSelectedTabForAdmin(state, tab) {
			state.selectedTabForAdmin = tab;
		},
		setSelectedTabForUser(state, tab) {
			state.selectedTabForUser = tab;
		},
		setTenantId(state, tenatnId) {
			state.tenantId = tenatnId;
		},
		setUser(state, user) {
			Object.keys(state.user).forEach((key) => {
				state.user[key] = user[key];
			});
		},
		setTenant(state, tenant) {
			state.tenant = tenant;
		}
	},
	actions: {
		setAccountToken({ commit }, accountToken) {
			commit('setAccountToken', accountToken);
		},
		setEnv({ commit }, env) {
			commit('setEnv', env);
		},
		setSelectedTabForAdmin({ commit }, tab) {
			commit('setSelectedTabForAdmin', tab);
		},
		setSelectedTabForUser({ commit }, tab) {
			commit('setSelectedTabForUser', tab);
		},
		setTenantId({ commit }, tenatnId) {
			commit('setTenantId', tenatnId);
		},
		setUser({ commit }, user) {
			commit('setUser', user);
		},
		setTenant({ commit }, tenant) {
			commit('setTenant', tenant);
		}
	}
});

export default store;
