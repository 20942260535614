import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import Store from '@/store/';
import customAxios from '@/plugins/custom-axios';
import qs from 'qs';
import VueGtag from 'vue-gtag';
import camelcaseKeys from 'camelcase-keys';
import { parseDomain, ParseResultType } from 'parse-domain';
import without from 'lodash/without';

import envIdentify from '@/lib/env-identify';

import Home from '@/pages/views/Home.vue';
import NewFeedback from '@/pages/views/NewFeedback.vue';
import FeedbacksPage from '@/pages/views/FeedbacksPage.vue';
import FeedbackCommentsPage from '@/pages/views/FeedbackCommentsPage.vue';
import MyFeedbacksPage from '@/pages/views/MyFeedbacksPage.vue';
import MyCommentFeedbacksPage from '@/pages/views/MyCommentFeedbacksPage.vue';
import MyVoteFeedbacksPage from '@/pages/views/MyVoteFeedbacksPage.vue';

import AdminHome from '@/pages/views/AdminHome.vue';
import AdminFeedbacks from '@/pages/views/AdminFeedbacks.vue';
import AdminFeedbackComments from '@/pages/views/AdminFeedbackComments.vue';
import AdminAccounts from '@/pages/views/AdminAccounts.vue';
import UnexpectedError from '@/pages/views/UnexpectedError.vue';
import NotLogined from '@/pages/views/NotLogined.vue';
import NotFound from '@/pages/views/NotFound.vue';
import NoAuth from '@/pages/views/NoAuth.vue';
import AdminTenant from '@/pages/views/AdminTenant.vue';

Vue.use(VueRouter);

const getTenantId = () => {
	const parseResult = parseDomain(window.location.hostname);
	if (!parseResult.type === ParseResultType.Listed) throw new Error(`invalid domain`);

	const tenatnIds = without(parseResult.subDomains, 'cat-voice', 'dev', 'sandbox');
	if (!tenatnIds.length) throw new Error(`tenantId not found `);
	const tenatnId = tenatnIds[0];

	return tenatnId;
};

const routes = [
	{
		path: '/login',
		// eslint-disable-next-line no-unused-vars
		redirect: (to) => {
			const { url, tenantId, clientUri, type } = to.query;
			window.location.href = url
				? `${clientUri}/login/?${qs.stringify({ url, tenant_id: tenantId, type })}`
				: `${clientUri}/login/`;
		}
	},
	{
		path: '/error',
		component: UnexpectedError
	},
	{
		path: '/notlogin',
		name: 'notlogin',
		component: NotLogined
	},
	{
		path: '/',
		name: 'home',
		meta: { requiresAuth: true },
		component: Home,
		children: [
			{
				path: '/',
				component: FeedbacksPage
			},
			{
				path: '/myfeedbacks',
				component: MyFeedbacksPage
			},
			{
				path: '/myvote/feedbacks',
				component: MyVoteFeedbacksPage
			},
			{
				path: '/mycomment/feedbacks',
				component: MyCommentFeedbacksPage
			},
			{
				path: '/new',
				component: NewFeedback
			},
			{
				path: '/feedback/:id/comments',
				component: FeedbackCommentsPage
			},
			{
				path: '/notfound',
				component: NotFound
			},
			{
				path: '/noauth',
				component: NoAuth
			},
			{
				path: '/admin',
				meta: { admin: true },
				component: AdminHome,
				name: 'admin',
				children: [
					{
						path: '/',
						component: AdminFeedbacks
					},
					{
						path: 'feedback/:id/comments',
						component: AdminFeedbackComments,
						name: 'adminFeedbackComments'
					},
					{
						path: 'accounts',
						component: AdminAccounts
					},
					{
						path: 'tenant',
						component: AdminTenant
					}
				]
			},
			{
				path: '*',
				beforeEnter: (to, from, next) => {
					next('/');
				}
			}
		]
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach(async (to, from, next) => {
	if (to.path === '/error') return next();

	try {
		const tenantId = getTenantId();
		Store.dispatch('setTenantId', tenantId);

		if (to.matched.some((record) => record.meta.requiresAuth)) {
			try {
				const {
					data: { token: accountToken, env, user }
				} = await axios.get(`/login/token`);
				const camelcasedUser = camelcaseKeys(user);

				Store.dispatch('setAccountToken', accountToken);
				Store.dispatch('setEnv', env);
				Store.dispatch('setUser', camelcasedUser);
				Vue.prototype.$axios = customAxios(accountToken, tenantId);

				// テナント存在チェック
				try {
					const { data } = await Vue.prototype.$axios.self.get();
					Store.dispatch('setTenant', camelcaseKeys(data));
				} catch (error) {
					if (to.path !== '/notfound') return next({ path: '/notfound' });
				}

				// グループ指定ありの場合、グループに所属していない場合はアクセス不可
				const { groupId } = Store.state.tenant;
				if (groupId)
					try {
						const {
							data: { groupUsers }
						} = await Vue.prototype.$axios.oidc.get(`/api/v1/group/${groupId}/users`);

						const { shiftAccountId } = Store.state.user;
						if (!groupUsers.some((groupUser) => groupUser.accountId === shiftAccountId))
							throw new Error('You are not a member of the group');
					} catch (e) {
						if (to.path !== '/noauth') return next({ path: '/noauth' });
					}

				// 管理者以外は管理画面にアクセスできない
				if (
					to.matched.some((record) => record.meta.admin) &&
					!(camelcasedUser.role === 'privilege' || camelcasedUser.role === 'admin')
				)
					next({ path: '/' });
			} catch (e) {
				console.error(e);

				const {
					data: { client_uri: clientUri }
				} = await axios.get(`/logout/client/uri`);

				return next({
					path: '/login',
					query: { url: to.path, tenantId, clientUri, type: to.query.type }
				});
			}
		}

		return next();
	} catch (e) {
		console.error(e);
		return next({ path: '/error', query: { msg: e.message } });
	}
});

Vue.use(
	VueGtag,
	{
		config: { id: envIdentify() === 'env-production' ? 'FIXME GA_ID' : 'UA-DUMY123-1' }
	},
	router
);

export default router;
