<template>
	<v-container>
		<v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbItems"></v-breadcrumbs>

		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="clearLabel()"
		>
			{{ $t(`errors.${errorLabel}`) }}
		</v-alert>

		<v-row class="justify-center">
			<v-col cols="12" md="4" lg="4" xl="4">
				<v-card>
					<v-card-title class="pb-0">{{ $t(`myvote_feedbacks.card.title`) }}</v-card-title>
					<v-card-text>
						<v-rating
							v-model="avaiableVoteCount"
							empty-icon="mdi-thumb-up-outline"
							full-icon="mdi-thumb-up"
							hover
							class="d-flex justify-center"
							:readonly="disabled"
						/>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" md="8" lg="8" xl="8">
				<h2 class="mb-2">{{ $t(`myvote_feedbacks.list_items.title`) }}</h2>
				<v-list v-if="!isNoData" three-line class="overflow-y-auto" :max-height="maxListHeight">
					<template v-for="(feedback, index) in voteFeedbacks">
						<v-list-item :key="feedback.id">
							<v-list-item-action>
								<v-card>
									<v-card-title class="justify-center pa-1">{{ feedback.votesCast }}</v-card-title>
									<v-card-text class="d-flex justify-center py-0">
										{{ $t(`myvote_feedbacks.list_items.vote_count`) }}
									</v-card-text>
									<v-divider />
									<v-card-text class="d-flex justify-center py-1">
										<v-icon
											dense
											color="primary"
											@click.stop="plusOneVote(feedback)"
											:disabled="voteDisabled(avaiableVoteCount, feedback)"
										>
											mdi-thumb-up
										</v-icon>
										<v-icon dense color="secondary" @click="minusOneVote(feedback)" class="ml-2">
											mdi-thumb-down
										</v-icon>
									</v-card-text>
								</v-card>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{ feedback.title }}</v-list-item-title>
								<v-list-item-subtitle>{{ feedback.contents }}</v-list-item-subtitle>
								<v-row class="pt-2">
									<v-col cols="1" class="d-flex justify-center">
										<v-icon class="pl-2"> mdi-account-circle </v-icon>
									</v-col>
									<v-col cols="6" class="d-flex flex-column px-1">
										<div class="text--secondary text-subtitle-2">
											{{
												$t(`myvote_feedbacks.list_items.user`, {
													name: feedback.feedbackAccountName
												})
											}}
										</div>
										<div class="text--secondary text-subtitle-2">
											{{
												$t(`myvote_feedbacks.list_items.created_at`, {
													date: formatedDate(feedback.createdAt)
												})
											}}
										</div>
										<div class="text-body-2">
											<v-chip
												small
												:color="feedback.category === 'request' ? 'indigo' : 'blue-grey'"
												class="white--text"
											>
												{{ $t(`feedbacks.menu.category.${feedback.category}`) }}
											</v-chip>

											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-if="
															feedback.feedbackAccountRole === 'privilege' ||
															feedback.feedbackAccountRole === 'admin'
														"
														text-color="white"
														class="pl-1"
														v-bind="attrs"
														v-on="on"
														>mdi-security
													</v-icon>
												</template>
												<span>{{ $t(`feedbacks.list_items.is_privileged`) }}</span>
											</v-tooltip>
										</div>
									</v-col>
									<v-col cols="3" class="d-flex align-center pr-0">
										<v-spacer />
										<v-chip small label :color="statusColor(feedback.status)" text-color="white">
											{{ $t(`feedbacks.menu.status.${feedback.status}`) }}
										</v-chip>
									</v-col>
									<v-col cols="2" class="d-flex align-center pl-0">
										<v-spacer />
										<v-btn disabled small text rounded class="ml-1">
											<v-icon left> mdi-comment-multiple-outline </v-icon>
											{{ feedback.totalComment }}
										</v-btn>
									</v-col>
								</v-row>
							</v-list-item-content>
						</v-list-item>
						<v-divider v-if="index < voteFeedbacks.length - 1" :key="index" />
					</template>
					<div v-intersect="onIntersect"></div>
				</v-list>
				<v-alert v-if="!loading && isNoData" dense type="info" class="mt-4">
					{{ $t(`myvote_feedbacks.list_items.no_data`) }}
				</v-alert>
				<v-progress-linear v-show="loading" indeterminate />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { DateTime } from 'luxon';

export default {
	name: 'MyVoteFeedbacksPage',
	data: () => ({
		disabled: true,
		rating: 0,
		voteFeedbacks: [],
		loading: false,
		limit: 10,
		counter: 0,
		errorLabel: null
	}),
	computed: {
		breadcrumbItems() {
			return [
				{
					text: this.$t('myvote_feedbacks.breadcrumbs.home'),
					disabled: false,
					to: '/'
				},
				{
					text: this.$t('myvote_feedbacks.breadcrumbs.vote_feedbacks'),
					disabled: true
				}
			];
		},
		avaiableVoteCount() {
			let totalVoteCast = 0;
			this.voteFeedbacks.forEach((voteFeedback) => {
				totalVoteCast += voteFeedback.votesCast;
			});
			return 5 - totalVoteCast;
		},
		isNoData() {
			return !this.voteFeedbacks.length;
		},
		maxListHeight() {
			return window.innerHeight - 180;
		},
		formatedDate() {
			return (unixtime) =>
				DateTime.fromSeconds(unixtime).setLocale(this.$i18n.locale).toFormat('yyyy-LL-dd HH:mm');
		},
		voteDisabled() {
			return (avaiableVoteCount, feedback) =>
				avaiableVoteCount === 0 ||
				feedback.status === 'developing' ||
				feedback.status === 'completed' ||
				feedback.status === 'resolved' ||
				feedback.status === 'rejected';
		},
		statusColor() {
			return (status) => {
				switch (status) {
					case 'entry':
						return 'cyan darken-3';
					case 'inprogress':
						return 'deep-orange darken-3';
					case 'developing':
						return 'green darken-3';
					case 'completed':
						return 'pink darken-3';
					case 'resolved':
						return 'orange darken-3';
					case 'rejected':
						return 'deep-purple darken-3';
					default:
						return 'grey';
				}
			};
		}
	},
	async created() {
		await this.init();
	},
	methods: {
		async init() {
			this.loading = true;

			try {
				await this.fetchVoteFeedbacks();
				this.randomSetTimeout();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.loading = false;
			}
		},
		async fetchVoteFeedbacks() {
			const {
				data: { voteFeedbacks }
			} = await this.$axios.self.get(`/vote/feedbacks`);
			this.voteFeedbacks = voteFeedbacks;
		},
		async plusOneVote(feedback) {
			try {
				this.clearLabel();
				await this.$axios.self.patch(`/feedback/${feedback.feedbackId}/vote`, {
					votes_cast: feedback.votesCast + 1
				});

				await this.init();
				this.reset();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		async minusOneVote(feedback) {
			try {
				this.clearLabel();
				await this.$axios.self.patch(`/feedback/${feedback.feedbackId}/vote`, {
					votes_cast: feedback.votesCast - 1
				});

				await this.init();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		reset() {
			this.disabled = true;
			this.selected = null;
			this.rating = 0;
		},
		handleResize() {
			this.height = window.innerHeight;
		},
		onIntersect(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.loading = true;
				this.randomSetTimeout();
			}
		},
		randomSetTimeout() {
			const random = Math.floor(Math.random() * 100) + 500;

			setTimeout(() => {
				this.counter += 1;
				this.loading = false;
			}, random);
		},
		clearLabel() {
			this.errorLabel = null;
		}
	}
};
</script>
