<template>
	<v-container>
		<v-breadcrumbs class="pl-0 pt-0" :items="breadcrumbItems"></v-breadcrumbs>

		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="clearLabel()"
		>
			{{ $t(`errors.${errorLabel}`) }}
		</v-alert>

		<v-row>
			<v-col cols="12" md="4" lg="4" xl="4">
				<v-card>
					<v-card-title class="pb-0">{{ $t(`myvote_feedbacks.card.title`) }}</v-card-title>
					<v-card-text>
						<v-rating
							v-model="avaiableVoteCount"
							empty-icon="mdi-thumb-up-outline"
							full-icon="mdi-thumb-up"
							hover
							class="d-flex justify-center"
							readonly
						/>
					</v-card-text>
				</v-card>

				<v-card class="mt-2">
					<v-card-title class="d-flex flex-column align-start">
						{{ feedback.title }}

						<div>
							<v-chip small label :color="statusColor(feedback.status)" text-color="white">
								{{ $t(`feedbacks.menu.status.${feedback.status}`) }}
							</v-chip>

							<v-btn disabled small text rounded>
								<v-icon left>mdi-thumb-up </v-icon>
								{{ feedback.totalVote }}
							</v-btn>
						</div>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<div class="mb-4 text-body-2" style="white-space: pre-line">
							{{ feedback.contents }}
						</div>

						<v-row>
							<v-col cols="2" class="d-flex justify-center px-0">
								<v-icon> mdi-account-circle </v-icon>
							</v-col>
							<v-col cols="10" class="d-flex flex-column px-0">
								<div class="text--secondary text-subtitle-2">
									{{
										$t(`feedbacks.list_items.user`, {
											name: feedback.name
										})
									}}
								</div>
								<div class="text--secondary text-subtitle-2">
									{{
										$t(`feedbacks.list_items.created_at`, {
											date: formatedDate(feedback.createdAt)
										})
									}}
								</div>
								<div class="text-body-2">
									<v-chip
										small
										:color="feedback.category === 'request' ? 'indigo' : 'blue-grey'"
										class="white--text"
									>
										{{ $t(`feedbacks.menu.category.${feedback.category}`) }}
									</v-chip>

									<v-tooltip
										bottom
										v-if="feedback.role === 'privilege' || feedback.role === 'admin'"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-icon text-color="white" class="pl-1" v-bind="attrs" v-on="on">
												mdi-security
											</v-icon>
										</template>
										<span>{{ $t(`feedbacks.list_items.is_privileged`) }}</span>
									</v-tooltip>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn
							@click.stop="vote(feedback.id)"
							color="primary"
							block
							:disabled="disabled(avaiableVoteCount, feedback)"
						>
							{{ $t(`feedbacks.list_items.vote_btn`) }}
						</v-btn>
					</v-card-actions>
				</v-card>

				<v-card class="mt-2" :disabled="feedback.status === 'rejected'">
					<v-form v-model="valid" ref="form">
						<v-card-text>
							<v-textarea
								v-model="comment.contents"
								solo
								:rules="rules"
								validate-on-blur
								:counter="maxlen"
								rows="3"
								:disabled="feedback.status === 'rejected'"
							/>
						</v-card-text>
						<v-card-actions>
							<v-btn
								@click="sendComment"
								color="primary"
								class="white--text"
								block
								:disabled="!valid || feedback.status === 'rejected'"
							>
								{{ $t(`feedback_comments.post_comment.submit`) }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-col>

			<v-col cols="12" md="8" lg="8" xl="8">
				<h2 class="mb-2">
					{{
						$t(`feedback_comments.list_items.title`, {
							count: comments.length
						})
					}}
				</h2>
				<v-list v-if="!isNoData" class="overflow-y-auto" :max-height="maxListHeight">
					<template v-for="(comment, index) in displayComments">
						<v-list-item :key="comment.id">
							<v-list-item-action>
								{{ index + 1 }}
							</v-list-item-action>
							<v-list-item-content>
								<v-container class="pa-0">
									<v-row>
										<v-col cols="12" class="pb-0">
											<div
												v-if="!comment.editable"
												class="text-body-2"
												style="white-space: pre-line; overflow-wrap: break-word"
											>
												<span v-if="!comment.deletedAt">{{ comment.comments }}</span>
												<span v-else>
													{{ $t(`feedback_comments.list_items.delete_by_privilege`) }}
												</span>
											</div>
											<v-textarea
												v-else
												v-model="comment.comments"
												solo
												:rules="rules"
												validate-on-blur
												:counter="maxlen"
												:rows="Math.ceil(comment.comments.length / 70) + 1"
											/>
										</v-col>
										<v-col cols="1" class="d-flex justify-center px-0 pt-0">
											<v-icon> mdi-account-circle </v-icon>
										</v-col>
										<v-col cols="11" class="d-flex align-center px-0 pt-0">
											<div class="secondary--text text--lighten-1 text-subtitle-2">
												{{
													$t(`feedbacks.list_items.user`, {
														name: comment.name
													})
												}}
											</div>
											<div class="secondary--text text--lighten-1 text-subtitle-2 ml-2">
												{{
													$t(`feedbacks.list_items.created_at`, {
														date: formatedDate(comment.createdAt)
													})
												}}
											</div>

											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-if="comment.role === 'privilege' || comment.role === 'admin'"
														text-color="white"
														class="pl-1"
														v-bind="attrs"
														v-on="on"
														>mdi-security
													</v-icon>
												</template>
												<span>{{ $t(`feedback_comments.list_items.is_privileged`) }}</span>
											</v-tooltip>
										</v-col>
									</v-row>
								</v-container>
							</v-list-item-content>
							<v-list-item-action class="ml-4">
								<v-icon
									v-if="
										comment.accountId === accountId &&
										!comment.editable &&
										feedback.status !== 'rejected'
									"
									@click="editComment(comment)"
								>
									mdi-pencil-outline
								</v-icon>
								<v-icon
									v-else-if="comment.accountId !== accountId || feedback.status === 'rejected'"
									disabled
								>
									mdi-pencil-off
								</v-icon>
								<v-icon v-if="comment.editable" @click="saveComment(comment)">
									mdi-content-save
								</v-icon>
							</v-list-item-action>
							<v-list-item-action>
								<v-icon
									v-if="comment.accountId === accountId && feedback.status !== 'rejected'"
									@click="confirmDeleteComment(comment, index + 1)"
									:disabled="comment.editable"
								>
									mdi-delete-outline
								</v-icon>
								<v-icon v-else disabled> mdi-delete-off </v-icon>
							</v-list-item-action>
						</v-list-item>
						<v-divider v-if="index < displayComments.length - 1" :key="`${index}-divider`" />
					</template>
					<div v-intersect="onIntersect"></div>
				</v-list>
				<v-alert v-if="!loading && isNoData" dense type="info" class="mt-4">
					{{ $t(`feedback_comments.list_items.no_data`) }}
				</v-alert>
				<v-progress-linear v-show="loading" indeterminate />
			</v-col>
		</v-row>

		<v-dialog v-model="dialog.delete" max-width="600px" persistent>
			<v-card>
				<v-card-title>
					{{ $t(`feedback_comments.dialog.delete.title`) }}
				</v-card-title>
				<v-card-text>
					{{ $t(`feedback_comments.dialog.delete.text_confirm`, { number: comment.number }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn text @click="dialog.delete = false">
						{{ $t(`feedback_comments.dialog.delete.disagree_label`) }}
					</v-btn>
					<v-btn color="primary" @click="deleteComment">
						{{ $t(`feedback_comments.dialog.delete.agree_label`) }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { DateTime } from 'luxon';

export default {
	name: 'FeedbackCommentsPage',
	data: () => ({
		valid: false,
		maxlen: 65535,
		height: 0,
		feedbackId: null,
		feedback: {},
		comments: [],
		voteFeedbacks: [],
		comment: {
			number: null,
			id: '',
			contents: ''
		},
		dialog: { delete: false },
		loading: false,
		limit: 10,
		counter: 0,
		errorLabel: null
	}),
	computed: {
		breadcrumbItems() {
			return [
				{
					text: this.$t('feedback_comments.breadcrumbs.home'),
					disabled: false,
					to: '/'
				},
				{
					text: this.$t('feedback_comments.breadcrumbs.comment'),
					disabled: true
				}
			];
		},
		displayComments() {
			return this.comments.slice(0, this.limit * this.counter);
		},
		avaiableVoteCount() {
			let totalVoteCast = 0;
			this.voteFeedbacks.forEach((voteFeedback) => {
				totalVoteCast += voteFeedback.votesCast;
			});
			return 5 - totalVoteCast;
		},
		isNoData() {
			return !this.comments.length;
		},
		maxListHeight() {
			return this.height > 1000 ? 800 : this.height - 180;
		},
		rules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: 'コメント' }),
				(v) =>
					(v && v.length <= this.maxlen) ||
					this.$t('errors.maxlen', { key: 'コメント', maxlen: this.maxlen })
			];
		},
		formatedDate() {
			return (unixtime) =>
				DateTime.fromSeconds(Number(unixtime))
					.setLocale(this.$i18n.locale)
					.toFormat('yyyy-LL-dd HH:mm');
		},
		disabled() {
			return (avaiableVoteCount, feedback) =>
				avaiableVoteCount === 0 ||
				feedback.status === 'developing' ||
				feedback.status === 'completed' ||
				feedback.status === 'resolved' ||
				feedback.status === 'rejected';
		},
		accountId() {
			return this.$store.getters.user.accountId;
		},
		statusColor() {
			return (status) => {
				switch (status) {
					case 'entry':
						return 'cyan darken-3';
					case 'inprogress':
						return 'deep-orange darken-3';
					case 'developing':
						return 'green darken-3';
					case 'completed':
						return 'pink darken-3';
					case 'resolved':
						return 'orange darken-3';
					case 'rejected':
						return 'deep-purple darken-3';
					default:
						return 'grey';
				}
			};
		}
	},
	async created() {
		await this.init();
		this.height = window.innerHeight;
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		async init() {
			this.loading = true;

			if (!this.$route.params.id) this.$router.push('/error');
			this.feedbackId = this.$route.params.id;

			try {
				await this.fetchFeedback();
				await this.fetchComments();
				await this.fetchVoteFeedbacks();

				this.randomSetTimeout();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.loading = false;
			}
		},
		async fetchFeedback() {
			const { data } = await this.$axios.self.get(`/feedback/${this.feedbackId}`);
			this.feedback = data;
		},
		async fetchComments() {
			const {
				data: { feedbackComments }
			} = await this.$axios.self.get(`/feedback/${this.feedbackId}/comments`, {
				params: { isAsc: true }
			});
			this.comments = feedbackComments.map((v) => ({ ...v, editable: false }));
		},
		async fetchVoteFeedbacks() {
			const {
				data: { voteFeedbacks }
			} = await this.$axios.self.get(`/vote/feedbacks`);
			this.voteFeedbacks = voteFeedbacks;
		},
		async vote(feedbackId) {
			let existVote = null;
			try {
				const { data } = await this.$axios.self.get(`/feedback/${feedbackId}/vote`);
				existVote = data;
			} catch (e) {
				// 何もしない
			}

			try {
				this.clearLabel();
				if (existVote) {
					await this.$axios.self.patch(`/feedback/${feedbackId}/vote`, {
						votesCast: existVote.votesCast + 1
					});
					await this.init();
					return;
				}

				await this.$axios.self.post(`/feedback/${feedbackId}/vote`, {
					votesCast: 1
				});
				await this.init();
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 409:
						this.errorLabel = 'reach_max_vote_cast';
						break;
					default:
						break;
				}
			}
		},
		async sendComment() {
			try {
				this.clearLabel();
				await this.$axios.self.post(`/feedback/${this.feedbackId}/comment`, {
					comments: this.comment.contents
				});
				this.comment.contents = null;
				this.$refs.form.reset();

				await this.fetchComments();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		editComment(comment) {
			this.comments.filter((v) => v.id === comment.id)[0].editable = true;
		},
		async saveComment(comment) {
			try {
				this.clearLabel();
				await this.$axios.self.patch(`/feedback/${this.feedbackId}/comment/${comment.id}`, {
					comments: comment.comments
				});
				await this.fetchComments();
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		confirmDeleteComment(comment, number) {
			this.clearLabel();

			this.dialog.delete = true;
			this.comment.id = comment.id;
			this.comment.number = number;
		},
		async deleteComment() {
			try {
				await this.$axios.self.delete(`/feedback/${this.feedbackId}/comment/${this.comment.id}`, {
					data: { commentId: this.comment.id }
				});
				await this.fetchComments();

				this.dialog.delete = false;
				this.comment.id = null;
				this.comment.number = null;
			} catch (e) {
				this.errorLabel = 'error_unknown';
			} finally {
				this.dialog.delete = false;
			}
		},
		handleResize() {
			this.height = window.innerHeight;
		},
		onIntersect(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.loading = true;
				this.randomSetTimeout();
			}
		},
		randomSetTimeout() {
			const random = Math.floor(Math.random() * 100) + 500;

			setTimeout(() => {
				this.counter += 1;
				this.loading = false;
			}, random);
		},
		clearLabel() {
			this.errorLabel = null;
		}
	}
};
</script>
