<template>
	<div>
		<v-alert
			dense
			type="error"
			class="body-2"
			dismissible
			:value="!!errorLabel"
			@input="this.errorLabel = null"
		>
			<span v-if="!!errorLabel">{{ $t(`errors.${errorLabel}`) }}</span>
		</v-alert>

		<v-snackbar color="primary" class="body-2" top right timeout="1800" v-model="snackbar">
			<span v-if="snackbar">
				{{ $t(`tenant.card.tenant_message.input.success`) }}
			</span>
		</v-snackbar>

		<v-card elevation="1" :loading="loading">
			<v-card-title>
				{{ $t(`tenant.card.tenant_message.title`) }}
			</v-card-title>
			<v-card-subtitle>{{ $t(`tenant.card.tenant_message.subtitle`) }}</v-card-subtitle>
			<v-card-text>
				<v-switch v-model="isRequestable" :label="$t(`tenant.card.request`)" />
				<v-switch v-model="showMessage" :label="$t(`tenant.card.tenant_message.display`)" />
				<div class="d-flex align-center">
					<div class="mr-2">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									<v-switch
										v-model="showPreview"
										:label="$t(`tenant.card.tenant_message.input.preview`)"
									/>
								</div>
							</template>
							<span>{{ $t(`tenant.card.tenant_message.input.supplement`) }}</span>
						</v-tooltip>
					</div>
					<div class="flex-grow-1">
						<div v-if="showPreview">
							<v-alert
								dense
								type="info"
								class="body-2 text-truncate"
								color="primary lighten-1"
								text
							>
								<div v-html="convertMarkdown" />
							</v-alert>
						</div>
						<div v-else>
							<v-textarea
								v-model="textMessage"
								auto-grow
								clearable
								color="primary"
								:counter="maxlen"
								outlined
								rows="3"
								:rules="rules"
								:hint="$t('tenant.card.tenant_message.input.hint')"
								persistent-hint
								:label="$t(`tenant.card.tenant_message.input.placeholder`)"
								prepend-inner-icon="mdi-language-markdown-outline"
							/>
						</div>
					</div>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="primary" @click="save()" :disabled="showMessage && !convertMarkdown">
					<v-icon left> mdi-content-save</v-icon>
					{{ $t(`tenant.card.tenant_message.input.save`) }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
import { marked } from 'marked';

export default {
	name: 'AdminTenant',
	data: () => ({
		errorLabel: null,
		snackbar: false,
		loading: false,
		textMessage: '',
		showMessage: false,
		showPreview: false,
		isRequestable: false,
		minlen: 1,
		maxlen: 65535
	}),
	computed: {
		convertMarkdown() {
			return marked(this.textMessage);
		},
		rules() {
			return [
				(v) =>
					!this.showMessage ||
					v.length >= this.minlen ||
					this.$t('errors.minlen', { key: this.$t('labels.tenant_message'), minlen: this.minlen }),
				(v) =>
					v.length <= this.maxlen ||
					this.$t('errors.maxlen', { key: this.$t('labels.tenant_message'), maxlen: this.maxlen })
			];
		}
	},
	created() {
		this.init();
	},
	methods: {
		async init() {
			this.loading = true;
			try {
				await this.fetchTenant();
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		async fetchTenant() {
			const {
				data: { showMessage, htmlMessage, isRequestable }
			} = await this.$axios.self.get('/');
			this.showMessage = showMessage;
			this.textMessage = htmlMessage;
			this.isRequestable = isRequestable;
		},
		reversePreview() {
			this.showPreview = !this.showPreview;
		},
		async save() {
			try {
				await this.$axios.internalSelf.patch('/', {
					htmlMessage: this.convertMarkdown,
					showMessage: this.showMessage,
					isRequestable: this.isRequestable
				});
				await this.fetchTenant();
				this.snackbar = true;
			} catch (e) {
				this.errorLabel = 'unknown';
			}
		}
	}
};
</script>
